@import 'webresources-sources/scss/imports';

.map-component {
  .map-filter {
    label {
      &:focus-within {
        box-shadow: $box-shadow-ring;
      }

      input ~ .btn {
        background-color: $color-white;
        color: $color-gray-600;
        border: 1px solid $color-darkgreen-600;
        font-weight: $font-weight-normal;

        .bg-dark & {
          border: 1px solid $color-darkgreen-200;
        }

        @media (hover: hover) {
          &:hover {
            background-color: $color-darkgreen-200;
            border-color: $color-darkgreen-600;
            color: $color-darkgreen-600;

            .bg-dark & {
              background-color: $color-darkgreen-600;
              border-color: $color-darkgreen-200;
              color: $color-bluegray-400;
            }
          }
        }

        &:active {
          opacity: 0.8;
        }
      }

      input:checked ~ .btn {
        background-color: $color-darkgreen-600;
        border-color: $color-darkgreen-600;
        color: $color-white;

        .bg-dark & {
          background-color: $color-darkgreen-200;
          border-color: $color-darkgreen-200;
          color: $color-darkgray-900;
        }
      }
    }
  }

  .buttons {
    background: $color-white;

    .btn {
      background: $color-darkgray-700;
      border-radius: 0;

      &.btn-zoom-in {
        border-bottom: 1px solid $color-darkgray-900;
      }

      &::after {
        background-color: $color-darkgreen-200;
      }

      &:hover {
        &::after {
          background-color: $color-white;
        }
      }
    }
  }

  .btn-cluster {
    color: $color-darkgreen-500;

    &::after {
      background-color: $color-darkgray-700;
    }

    &:hover {
      color: $color-darkgreen-500;

      &::after {
        background-color: $color-darkgreen-400;
      }
    }
  }

  .btn-marker {
    &::after {
      background-color: $color-darkgreen-500;
    }

    &:hover {
      &::after {
        background-color: $color-darkgreen-400;
      }
    }

    &.marker-current {
      &::before {
        background: $color-darkgray-700;
      }

      &::after {
        background-color: $color-darkgreen-200;
      }
    }
  }

  /* InfoWindow */
  .info-window {
    background: $gradient-on-light;
  }
}
