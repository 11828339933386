/* bootstrap overwrite */
.map-component {
  /* InfoWindow */
}
.map-component .map-filter label:focus-within {
  box-shadow: 0 0 0 2px var(--focus-offset-color, #fff), 0 0 0 calc(2px + 2px) var(--focus-color, #c9d1e3);
}
.map-component .map-filter label input ~ .btn {
  background-color: #fff;
  color: #657785;
  border: 1px solid #007a62;
  font-weight: 400;
}
.bg-dark .map-component .map-filter label input ~ .btn {
  border: 1px solid #bcf0e8;
}
@media (hover: hover) {
  .map-component .map-filter label input ~ .btn:hover {
    background-color: #bcf0e8;
    border-color: #007a62;
    color: #007a62;
  }
  .bg-dark .map-component .map-filter label input ~ .btn:hover {
    background-color: #007a62;
    border-color: #bcf0e8;
    color: #e4eaf7;
  }
}
.map-component .map-filter label input ~ .btn:active {
  opacity: 0.8;
}
.map-component .map-filter label input:checked ~ .btn {
  background-color: #007a62;
  border-color: #007a62;
  color: #fff;
}
.bg-dark .map-component .map-filter label input:checked ~ .btn {
  background-color: #bcf0e8;
  border-color: #bcf0e8;
  color: #38506f;
}
.map-component .buttons {
  background: #fff;
}
.map-component .buttons .btn {
  background: #546983;
  border-radius: 0;
}
.map-component .buttons .btn.btn-zoom-in {
  border-bottom: 1px solid #38506f;
}
.map-component .buttons .btn::after {
  background-color: #bcf0e8;
}
.map-component .buttons .btn:hover::after {
  background-color: #fff;
}
.map-component .btn-cluster {
  color: #0f947a;
}
.map-component .btn-cluster::after {
  background-color: #546983;
}
.map-component .btn-cluster:hover {
  color: #0f947a;
}
.map-component .btn-cluster:hover::after {
  background-color: #45ad99;
}
.map-component .btn-marker::after {
  background-color: #0f947a;
}
.map-component .btn-marker:hover::after {
  background-color: #45ad99;
}
.map-component .btn-marker.marker-current::before {
  background: #546983;
}
.map-component .btn-marker.marker-current::after {
  background-color: #bcf0e8;
}
.map-component .info-window {
  background: linear-gradient(90deg, #0f947a 11.98%, #001f47 100%);
}